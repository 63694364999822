import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Context } from "../components/FullHeight";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
  }
  h1 {
    font-family: 'Hind', sans-serif;
  }
  p {
    font-family: 'Source Sans Pro', sans-serif;
  }
  a {
    color: white;
    text-decoration: none;
    :hover {
      color: honeydew;
    }
  }
`;

const Main = styled.main`
  display: grid;
  min-height: ${({ height }) => height + "px"};
  //background: pink;
  position: relative;
  padding: 0 10vw;
  z-index: 5;
`;

const Video = styled.video`
  background: #131313;
  position: absolute;
  object-fit: cover;
  object-position: center;
  width: 100%;
  min-height: ${({ height }) => height + "px"};
  top: 0;
  right: 0;
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  color: white;
  max-width: 390px;
  align-self: center;
  z-index: 10;
  h1 {
    font-size: 2rem;
    font-weight: 900;
    line-height: 1.1em;
    margin-bottom: 0;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75em;
  }
  @media screen and (min-width: 360px) {
    h1 {
      font-size: 3rem;
    }
  }
  @media screen and (min-width: 960px) {
    h1 {
      font-size: 5.25rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
`;

const Footer = styled.footer`
  position: absolute;
  background: #2e6c78;
  color: white;
  width: 100%;
  bottom: 20px;
  left: 0;
  z-index: 15;
  padding: 0 10vw;
  p {
    margin: 0.5em 0;
    font-weight: 300;
    text-align: center;
  }

  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: flex-end;
    p {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
`;

// markup
const IndexPage = () => {
  const { height } = React.useContext(Context);
  return (
    <>
      <GlobalStyle />
      <Video
        playsInline
        autoPlay
        muted
        loop
        poster=""
        id="bgvid"
        height={height}
      >
        <source
          src="https://res.cloudinary.com/dcnlacxzw/video/upload/v1610483916/Speedwater/speedwater_low_bitrate_dposnh.mp4"
          type="video/mp4"
        />
      </Video>
      <Main height={height}>
        <Content>
          <h1>DATA MATTERS!</h1>
          <p>
            We enable companies to standardize their data sets to create 360°
            customer profiles to be used in direct customer engagement.
          </p>
        </Content>
      </Main>
      <Footer>
        <p>
          {" "}
          <a href="mailto:contact@speedwater.com">contact@speedwater.com</a>
        </p>
        <p>©2021 Speedwater, Inc.</p>
      </Footer>
    </>
  );
};

export default IndexPage;
